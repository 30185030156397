import React, {ReactNode} from 'react'
import './App.scss'
import {AppState, useAppContext} from './useAppState'
import BootstrapData from './Bootstrap-Data/Bootstrap-Data'
import {MainContent} from './Main-Content/Main-Content'
import {ErrorBoundary} from './ErrorBoundary'
import AppError from './App-Error/App-Error'
import FeatureFlags from '@pluralsight/ps-design-system-featureflags'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {TransactionRoutes} from './TransactionRoutes'
import {SessionAuth} from './Router/SessionAuth/SessionAuth'
import {config} from './environment-config'

export default function App() {
    function DesignSystemFeatureFlags(props: {children: ReactNode}) {
        const {state} = useAppContext()
        const designSystemFeatureFlags =
            state.applicationData.featureConfig.designSystemFeatureFlags
        return <FeatureFlags flags={designSystemFeatureFlags}>{props.children}</FeatureFlags>
    }

    /* // To-Do: remove when shifting to session
     useEffect(authenticate, [])
     let [showLoadingDecorator, setShowLoadingDecorator] = useState(true)
     let [error, setError] = useState<string | null>(null)
 
     function authenticate() {
         api.get('user/authenticated')
             .then((res) => {
                 if (res.status.success) {
                     setShowLoadingDecorator(false)
                     identify(res.data.handle)
                 } else {
                     const currentLocation = window.location.href
                     window.location.assign(`/id?redirectTo=${encodeURIComponent(currentLocation)}`)
                 }
             })
             .catch((e) => {
                 setError(e.message)
                 console.error(e.stack)
             })
     }
 
     return !!error ? <div className='centerVerticalError'>{error}</div> : handleLoading()
 
     function handleLoading() {
         return showLoadingDecorator ? (
             <CenteredLoadingDecorator centerVertically={true} />
         ) : (*/
    return (
        <ErrorBoundary>
            <AppState>
                <AppError>
                    <SessionAuth>
                        <BootstrapData>
                            <DesignSystemFeatureFlags>
                                <Router>
                                    <Routes>
                                        <Route
                                            path={`${config.subscriptionLifecycle.browserPath}/transaction/*`}
                                            element={<TransactionRoutes />}
                                        />
                                        <Route
                                            path={`${config.subscriptionLifecycle.browserPath}/*`}
                                            element={<MainContent />}
                                        />
                                    </Routes>
                                </Router>
                            </DesignSystemFeatureFlags>
                        </BootstrapData>
                    </SessionAuth>
                </AppError>
            </AppState>
        </ErrorBoundary>
    )
}
